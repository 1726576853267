
.body{
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    position: fixed;
}

.container{
    width: 40rem;
    height: 20rem;
    background-color: white;
    border-radius: 1px;
    display: flex;
}

.left{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 1.5rem;
}

.inputContainer{
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    margin-bottom: 1rem;
}

.inputContainer>label{
    font-size: 0.85rem;
    font-weight: bold;
    margin-bottom: 0.1rem;
}

.inputContainer>input{
    font-size: 1.1rem;
    padding: 0.3rem 0.5rem;
    border: 1px solid #ccc;
    border-radius: 1px;
}

.inputContainer>input:focus{
    outline: none;
    border: 2px solid #8093f1;
}

.btn{
    color: white;
    background-color: #2f4468;
    font-size: 1.1rem;
    padding: 0.3rem 0.6rem;
    text-align: center;
    width: 100%;
    border-radius: 5px;
    font-weight: 600;
}

.btn:hover{
    background-color: #232e41;
    cursor: pointer;
    font-weight: 700;
}

.right{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    margin-top: 10px;
    margin-bottom: 0.7rem;
    font-size: 12px;
}

.rightItem{
    display: flex;
    align-items:self-start;
    width: 15rem;
}

.rightItem>.icon{
    font-size: 1rem;
    margin-right: 1rem;
    color: #2f4468;
    margin-top: 20px;
}

.details{
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
}
.details>h3{
    font-size: 1.5rem;
    margin: 0;
}

.details>span{
    color: #848C94;
}

.error{
    width: 80%;
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 0.9rem;
    box-shadow: 3px 3px 5px 0px rgba(0,0,0,0.75);
}

.errorLogo{
    background-color: #E44B5E;
    color: #fff;
    font-size: 1.4rem;
    padding: 0.4rem 0.3rem;
}

.errorSpan{
    font-size: 0.75rem;
    font-weight: 600;
}


@media screen and (max-width: 768px){
    .container{
        width: 20rem;
        height: 30rem;
        flex-direction: column;
    }

    .left{
        height: 15rem;
    }
}

body.active-modal {
    overflow-y: hidden;
}

.btn-modal {
    padding: 10px 20px;
    display: block;
    margin: 100px auto 0;
    font-size: 18px;
}

.modal, .overlay {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
}

.overlay {
    background: rgba(49,49,49,0.8);
}


.closeModal {
    display: flex;
    position: absolute;
    top: 11%;
    right: 20%;
    padding: 5px 7px;
    font-size: 2rem;
    font-weight: bold;
    background: none;
    color: #fff;
    border: none;
}

.closeModal:hover{
    cursor: pointer;
}

@media screen and (max-width: 800px) {
    .closeModal {
      top: 8%;
      right: 5%;
    }
}

@media screen and (max-width: 1100px) {
    .closeModal {
      top: 7%;
      right: 10%;
      padding: 5px 7px;
    }
}

.credHeading{
    font-size: 1.2rem;
    margin-bottom: 0;
    text-decoration-style: double; 
    text-decoration-color: #2f4468;
}

.loginHeading{
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 0;
    text-decoration-style: double; 
    text-decoration-color: #2f4468;
}





.right {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .rightItem {
    display: flex;
    align-items: self-start;
    width: 15rem;
    margin-bottom: 0.8rem;
  }
  
  .rightItem > .icon {
    font-size: 1.2rem;
    margin-right: 0.6rem;
    color: #2f4468;
    margin-top: 0.7rem;
  }
  
  .details {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
  }
  
  .details > h5 {
    font-size: 0.9rem;
    margin: 0;
  }
  
  .details > div {
    margin-top: 0.2rem;
    font-size: 0.8rem;
  }
  