.container{
    display: flex;
    align-items: center;
    justify-content: center;
}

.main{
    width: 80%;
}

.table{
    width: 100%;
    border-collapse: separate;
    border-spacing: 1rem;
}
.tbody>tr>td{
    text-align: center;
}

.tbody>tr>:nth-child(2){
    width: 50%;
}

.imageTd>img{
    border-radius: 1rem;
}

.cancel{
    margin-top: 0.3rem;
    font-size: 1.6rem;
}

@media screen and (max-width: 900px) {
    .main{
        width: 90%;
        overflow-x: auto;
    }
}