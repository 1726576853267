.header {
	height: 4rem;
	display: flex;
	align-items: center;
	justify-content: center;
  background-color: #2f4468 ;
	color: white;
}
.container {
	width: 82%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 1.2rem;
}

.logoLink{
  text-decoration: none;
}

.aot{
  font-size: 1.5rem;
  color: #fff;
  font-family: 'Poppins', sans-serif;
}

.links {
	display: flex;
	text-decoration: none;
	gap: 1.9rem;
	list-style-type: none;
}

.link {
	color: #eee;
	text-decoration: none;
	cursor: pointer;
}
.link:hover {
	color: #fff;
  filter: brightness(1.4);
	cursor: pointer;
}

.link::after {
	content: "";
	display: block;
	width: 0;
	height: 2px;
	background: white;
	transition:  width 0.3s ease 0s, left 0.3s ease 0s;
  
}

.link:hover::after {
	width: 100%;
  transition: all 300ms ease-in-out;
}

.active {
	color: #111;
}

.right {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 1.2rem;
}


.btn{
  all: unset;
  cursor: pointer;
  color: #86d1d9;
  font-size: 1.8rem;
  margin-top: 0.4rem;
}

.btn:hover {
  color: #FFF;
}

.cart{
    font-size: 1.7rem;
    cursor: pointer;
}
.cart:hover{
  transform: scale(1.1);
  color: #86d1d9;
}

.detailCart{
  margin-top: 0.8rem;
  display: flex;
  align-items: center;
  justify-self: start;
}

.myCart{
  display: inline-block;
  color: white;
  background-color: #ff4d6d;
  border-radius: 50%;
  font-size: 0.7rem;
  padding: 0.1rem 0.3rem;
  margin-top: -1rem;
  margin-left: -0.1rem;
}

.logout{
  display: flex;
  align-items: center;
  gap: 0.4rem;
  font-size: 1.2rem;
}


/* bar section */

.bar{
  background-color: #f7f7f7;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #111;
}

.barContainer{
  display: flex;
  width: 80%;
  align-items: center;
  justify-content: flex-end;
  font-size: 1rem;
}

.sort{
  margin-top: 0.8rem;
  display: flex;
  gap: 0.3rem;
}

.name{
  font-weight: 600;
  font-size: 1.8rem;
}

.select{
  font-size: 0.9rem;
  padding: 0.4rem 0.2rem;
  background-color: #fff;
  color: #10002b;
}

.filter{
  color: #333;
  padding-top: 0.2rem;
}

@media screen and (max-width: 600px) {
  .header{
    height: 10vh;
  }
  .bar{
    height: auto;
  }
}

body.active-modal {
  overflow-y: hidden;
}


.modal, .overlay {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
}

.overlay {
  background: rgba(49,49,49,0.8);
}


.closeModal {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px 7px;
}

