.body{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
    margin-top: 5rem;
    margin-bottom: 5rem;
}

.body>span{
    font-size: 1.4rem;
    color: #111;
    font-weight: bold;
}

.btn{
    color: #fff;
    background-color: #2db9c9;
    font-size: 1.3rem;
    padding: 0.4rem 0.9rem;
    border-radius: 2rem;
    border: none;
}

.btn:hover{
    cursor: pointer;
    background-color: #2f4468;
}