
.body{
    padding-top: 1rem;
    padding-bottom: 3rem;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f7f7f7;
}

.container{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 4rem;
    color: #111;
}

.item{
    border-radius: 3px;
    overflow: hidden;
    box-shadow: 0px 0px 1px 0px rgba(0,0,0,0.4);


    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    color: #ffffff;
    width: 15rem;
}

.left{
    display: block;
}

.left>img{
    height: 16rem;
    width: 100%;
}

.left>img:hover{
    transform: scale(1.01);
    cursor: pointer;
}

.mid{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 10rem;
}

.title{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: 600;
    font-size: 1.2rem;
    cursor: pointer;
    color: #111;
}

.desc{
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    text-align: center;
    color: #555;
    font-size: 0.84rem;
    line-height: 1.1rem;
    margin:0 0.3rem;
}

.quantity{
    
    text-align: center;
}

.quantity>input{
    font-size: 1.2rem;
    width: 30%;
    border-radius: 0.3rem;
}

.priceSection{
    height: 4rem;
    background-color: #eee;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.price{
    font-weight: 700;
    color: #2f4468;
    font-size: 1.2rem;
}

.btn{
    text-transform: uppercase;
    background-color: #2db9c9;
    color: #fff;
    font-size: 0.7rem;
    padding: 0.6rem 0.8rem;
    border-radius: 10rem;
    border: none;
    cursor: pointer;
    transition: all 0.3s ease;
}

.btn:hover{
    top: -2px;
    box-shadow: 1px 1px 3px 0px rgba(0,0,0,0.75);
}

.quantity{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.4rem;
}

.qtyamt{
    font-size: 1.3rem;
    color: #000;
    font-weight: bold;
}

.caret{
    font-size: 1.5rem;
    color: #2db9c9;
    cursor: pointer;
}

@media screen and (max-width: 1200px) {
    .container{
        grid-template-columns: repeat(3, 1fr);
    }
}

@media screen and (max-width: 1000px) {
    .container{
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 768px) {
    .container{
        grid-template-columns: repeat(1, 1fr);
    }
}